import _, { FieldWithPossiblyUndefined } from 'lodash';
import { AxiosRequestConfig, AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { STORAGE_KEY } from '../constants';

type RequestFunction = (params?: any) => Promise<any>;

export const getAxiosConfig = () => {
  const headers = window.sessionStorage.getItem(STORAGE_KEY) ? { Authorization: `Bearer ${window.sessionStorage.getItem(STORAGE_KEY)}` } : null;
  const axiosConfig: AxiosRequestConfig = {
    baseURL: `${process.env.REACT_APP_BACKEND_API}`,
    ...(headers && { headers } ),
    validateStatus: function (status: number) {
      return status >= 200 && status < 399; // default
    }
  };
  return axiosConfig;
}

export const handleApiRequestPromise = async (requestFunction: RequestFunction, params?: any): Promise<any> => {
  try {
    const result = await requestFunction(params);
    return result;
  } catch (e) {
    const errorMessage = getAxiosErrorMessage(e);
    throw new Error(errorMessage);
  }
}

export const getAxiosErrorMessage = (e: AxiosError) => {
  const errorMessage = _.get(e, 'response.data') ? _.get(e, 'response.data') : _.get(e, 'message');
  return _.toString(errorMessage);
}

export const handleError = (errorMessage: string) => toast.error(`Something went wrong: ${errorMessage}`);