import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout as MobileLayout } from 'src/layouts/mobile';

const MultiStepForm = lazy(() => import('src/sections/mobile-app/multi-step-form'));

export const mobileRoutes: RouteObject[] = [
  {
    path: 'mobile',
    element: (
      <MobileLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </MobileLayout>
    ),
    children: [
      {
        index: true,
        path: 'form',
        element: <MultiStepForm />
      }
    ]
  }
];
