import _ from 'lodash';
import { Category } from 'src/types/category';
import { Contact } from 'src/types/contact';
import isMobilePhone from 'validator/lib/isMobilePhone';

const getWasteCategoryNameById = (category: Category[], _id: string) => _.get(_.find(category, { _id }), 'name');

const getContactNameById = (contacts: Contact[], _id: string) => _.get(_.find(contacts, { _id }), 'personName');

const replaceObjectValuesWithTrue = (obj: { [key: string]: unknown }) => {
  const result: { [key: string]: unknown } = {};
  _.forOwn(obj, (value, key) => {
    if (_.isObject(value)) {
      result[key] = replaceObjectValuesWithTrue(value as { [key: string]: unknown });
    } else {
      result[key] = true;
    }
  });
  return result;
}

const isValidPhone = (dialCode: number | string, fullPhone: number | string) => {
  // the commented version is the previous implementation
  // const fullNumber = _.toString(`${dialCode}${phone}`);
  // const cleanedLocalNumber = _.toString(phone).replace(/\D/g, '');
  // const cleanedFullNumber = _.trim(fullNumber); // number in the form +420111222333
  const cleanedFullNumber = _.trim(_.toString(fullPhone));
  const cleanedLocalNumber = _.trimStart(cleanedFullNumber, _.toString(dialCode))
  const validMobilePhone = isMobilePhone(cleanedFullNumber, 'any', { strictMode: true });
  if (validMobilePhone || cleanedLocalNumber.length === 9) {
    return true;
  }
  return false;
}

interface ArrayOptions {
  [key: string]: any;
}

interface Options {
  labelTitle: string;
  labelDescription?: string[];
}

/*
const getAutocompleteOptions = (array: ArrayOptions[], options: Options) => _.map(array, (item) => {
  const { _id: value } = item; //  this is the incoming array
  const label = _.get(item, options.label);

  const additionalFields: { [key: string]: any }  = {};

  _.forEach(options?.valueFields, (field) => {
    if (_.get(item, field)) {
      additionalFields[field] = _.get(item, field)
    }
  });

  return {
    label,
    value,
    ...additionalFields
  };
});
*/
const getAutocompleteOptions = (params: any) => [];

const getOptionsWithLabel = (array: ArrayOptions[], options: Options) => _.map(array, item => {
  const { labelTitle, labelDescription } = options;
  console.log(_.values(_.pick(item, _.castArray(labelDescription))));
  const labelDescriptionValues = _.compact(_.values(_.pick(item, _.castArray(labelDescription))));
  const joinedLabelDescription = _.join(labelDescriptionValues, ' | ');
  return {
    value: _.get(item, '_id'),
    labelTitle: _.toString(_.get(item, labelTitle)),
    labelDescription: joinedLabelDescription
  }
});

const getCustomEnumeration = (projectSettings: any, collectionName: string, fieldName: string) => {
  console.log(projectSettings);
  const collection = _.find(projectSettings, { collectionName });
  console.log(collection);
  const customEnumArray = _.get(collection, 'customEnum');
  console.log(customEnumArray);
  const customEnumFieldName = _.find(customEnumArray, { fieldName });
  console.log(customEnumFieldName);
  const customEnumOptions = _.get(customEnumFieldName, 'options');
  console.log(customEnumOptions);
  return customEnumOptions;
}

const getSelectOptions = (array: string[]) => 
  _.map(array, (item) => ({
    label: item,
    value: item
  }))


export {
  getAutocompleteOptions,
  getOptionsWithLabel,
  getWasteCategoryNameById,
  getContactNameById,
  replaceObjectValuesWithTrue,
  isValidPhone,
  getCustomEnumeration,
  getSelectOptions
};
