import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';

const IndexPage = lazy(() => import('src/pages/dashboard/index'));

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));
const AnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

//MY ROUTES

const ContactListPage = lazy(() => import('src/pages/dashboard/contact/list'));
const ContactCreatePage = lazy(() => import('src/pages/dashboard/contact/create'));
const ContactDetailPage = lazy(() => import('src/pages/dashboard/contact/list'));

const LocationListPage = lazy(() => import('src/pages/dashboard/location/list'));
const LocationCreatePage = lazy(() => import('src/pages/dashboard/location/create'));
const LocationEditPage = lazy(() => import('src/pages/dashboard/location/create'));
const LocationDetailPage = lazy(() => import('src/pages/dashboard/location/list'));

const InventoryPage = lazy(() => import('src/pages/dashboard/inventory/list'));
const InventoryCreatePage = lazy(() => import('src/pages/dashboard/inventory/create'));

const InventoryTypeListPage = lazy(() => import('src/pages/dashboard/inventoryType/list'));
const InventoryTypeCreatePage = lazy(() => import('src/pages/dashboard/inventoryType/create'));

const ProjectSettingListPage = lazy(() => import('src/pages/dashboard/projectSetting/list'));
const ProjectSettingEditPage = lazy(() => import('src/pages/dashboard/projectSetting/edit'));

const TagListPage = lazy(() => import('src/pages/dashboard/tag/list'));
const TagCreatePage = lazy(() => import('src/pages/dashboard/tag/create'));
const TagEditPage = lazy(() => import('src/pages/dashboard/tag/create'));

const ActivityTypeListPage = lazy(() => import('src/pages/dashboard/activityType/list'));
const ActivityTypeCreatePage = lazy(() => import('src/pages/dashboard/activityType/create'));
const ActivityTypeEditPage = lazy(() => import('src/pages/dashboard/activityType/create'));

const ActivityListPage = lazy(() => import('src/pages/dashboard/activity/list'));
const ActivityCreatePage = lazy(() => import('src/pages/dashboard/activity/create'));
const ActivityEditPage = lazy(() => import('src/pages/dashboard/activity/create'));

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'account',
        element: <AccountPage />
      },
      {
        path: 'analytics',
        element: <AnalyticsPage />
      },
      {
        path: 'blank',
        element: <BlankPage />
      },
      {
        path: 'activity-type',
        children: [
          {
            index: true,
            element: <ActivityTypeListPage />
          },
          {
            path: 'create',
            element: <ActivityTypeCreatePage />
          },
          {
            path: 'edit/:activityTypeId',
            element: <ActivityTypeEditPage />
          }
        ]
      },
      {
        path: 'activity',
        children: [
          {
            index: true,
            element: <ActivityListPage />
          },
          {
            path: 'create',
            element: <ActivityCreatePage />
          },
          {
            path: 'edit/:activityId',
            element: <ActivityEditPage />
          }
        ]
      },
      {
        path: 'project-setting',
        children: [
          {
            index: true,
            element: <ProjectSettingListPage />
          },
          {
            path: 'edit/:settingId',
            element: <ProjectSettingEditPage />
          }
        ]
      },
      {
        path: 'contact',
        children: [
          {
            index: true,
            element: <ContactListPage />
          },
          {
            path: 'create',
            element: <ContactCreatePage />
          },
          {
            path: ':contactId',
            element: <ContactDetailPage />
          }
        ]
      },
      {
        path: 'location',
        children: [
          {
            index: true,
            element: <LocationListPage />
          },
          {
            path: 'create',
            element: <LocationCreatePage />
          },
          {
            path: 'edit/:locationId',
            element: <LocationCreatePage />
          },
          {
            path: ':locationId',
            element: <LocationDetailPage />
          }
        ]
      },
      {
        path: 'inventory',
        children: [
          {
            index: true,
            element: <InventoryPage />
          },
          {
            path: 'create',
            element: <InventoryCreatePage />
          }
        ]
      },
      {
        path: 'project-setting',
        children: [
          {
            index: true,
            element: <ProjectSettingListPage />
          },
          {
            path: 'edit/:settingId',
            element: <ProjectSettingEditPage />
          }
        ]
      },
      {
        path: 'tag',
        children: [
          {
            index: true,
            element: <TagListPage />
          },
          {
            path: 'create',
            element: <TagCreatePage />
          },
          {
            path: 'edit/:tagId',
            element: <TagEditPage />
          }
        ]
      },
      {
        path: 'inventory-type',
        children: [
          {
            index: true,
            element: <InventoryTypeListPage />
          },
          {
            path: 'create',
            element: <InventoryTypeCreatePage />
          },
          {
            path: 'edit/:inventoryTypeId',
            element: <InventoryTypeCreatePage />
          }
        ]
      }
    ]
  }
];
