import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import toast from 'react-hot-toast'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { InventoryType } from 'src/types/inventoryType';
import { getAxiosConfig, handleApiRequestPromise } from 'src/api/api';

const createInventoryType = async (inventoryType: InventoryType | undefined): Promise<InventoryType> => {
  const axiosConfig = getAxiosConfig();
  const { data }: AxiosResponse<InventoryType> =  await axios.post('inventory-type', { inventoryType }, axiosConfig);
  return data;
}

const findInventoryTypes = async (): Promise<InventoryType[]> => {
  const axiosConfig = getAxiosConfig();
  const { data }: AxiosResponse<InventoryType[]> =  await axios.get('inventory-type', axiosConfig);
  return _.sortBy(data, (item) => item.name);
};

const deleteInventoryType = async (objectId: string): Promise<string> => {
  const axiosConfig = getAxiosConfig();
  await axios.delete(`inventory-type/${objectId}`, axiosConfig);
  return objectId
};

export const useInventoryTypes = () => {
  return useQuery({
    queryKey: ["inventoryType"],
    queryFn: async () => {
      return handleApiRequestPromise(findInventoryTypes)
    },
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000, // consider data fresh for 60min
    cacheTime: 1000 * 60 * 60 * 24, // keep data in cache for 24hrs
    networkMode: 'offlineFirst',
    onError: (errorMessage: string) => toast.error(`Something went wrong: ${errorMessage}`)
  });
}

export const useCreateInventoryType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (inventoryType: InventoryType) => handleApiRequestPromise(createInventoryType, inventoryType),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inventoryTypes'] })
    }
  })
}

export const useDeleteInventoryType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => handleApiRequestPromise(deleteInventoryType, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inventoryTypes'] })
    },
    onError: (errorMessage: string) => toast.error(`Something went wrong: ${errorMessage}`)
  })
}