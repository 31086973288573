import type { FC } from 'react';
import { useCallback } from 'react';
import { useRouter } from 'src/hooks/use-router';
import { useAuth } from 'src/hooks/use-auth';
import toast from 'react-hot-toast';
import { Button } from '@mui/material';
import { paths } from 'src/paths';

export const AccountButton: FC = () => {
  const router = useRouter();
  const { signOut } = useAuth();


  const handleLogout = useCallback(
    async (): Promise<void> => {
      try {
        await signOut();
        router.push(paths.index);
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
      }
    },
    [signOut, router]
  );
  return (
    <Button color="inherit"
      onClick={handleLogout}>
      Logout</Button>

    /*
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32
          }}
          src={user.avatar}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    */
  );
};
