import { useEffect } from 'react';
import _ from 'lodash';
import { addDays, subDays, subHours, subMinutes } from 'date-fns';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { useSettings } from 'src/hooks/use-settings';
import { OverviewTips } from 'src/sections/dashboard/overview/overview-tips';
import type { Page as PageType } from 'src/types/page';
import { OverviewCard } from 'src/sections/dashboard/overview/overview-card';
import { OverviewTable as OverviewInventoryItems  } from 'src/sections/dashboard/overview/overview-table';
import { useInventoryItems } from 'src/api/inventory';
import { useInventoryTypes } from 'src/api/inventoryType';
import { useTags } from 'src/api/tag';
import { useContacts } from 'src/api/contact';
import { paths } from 'src/paths';

const Page: PageType = () => {

  const { data: inventoryTypes } = useInventoryTypes();
  const { data: tags } = useTags();
  const { data: inventory } = useInventoryItems();
  const { data: contacts } = useContacts();
  
  const inventorySize = _.size(inventory);
  const tagsSize = _.size(tags);
  const inventoryTypesSize = _.size(inventoryTypes);
  const contactsSize = _.size(contacts);

  const settings = useSettings();

  usePageView();

  return (
    <>
      <Seo title="Dashboard: Overview" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth={settings.stretch ? false : 'xl'}>
          <Grid
            container
            disableEqualOverflow
            spacing={{
              xs: 3,
              lg: 4
            }}
          >
            <Grid xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={4}
              >
                <div>
                  <Typography variant="h4">
                    Overview
                  </Typography>
                </div>
              </Stack>
            </Grid>
            <Grid
              xs={12}
              md={4}
            >
              <OverviewCard amount={tagsSize} title='Tags' linkPath={paths.dashboard.tag.index} iconSrc='/assets/iconly/iconly-glass-info.svg' />
            </Grid>
            <Grid
              xs={12}
              md={4}
            >
              <OverviewCard amount={inventoryTypesSize} title='Inventory Types' linkPath={paths.dashboard.inventoryType.index} iconSrc='/assets/iconly/iconly-glass-info.svg' />
            </Grid>
            <Grid
              xs={12}
              md={4}
            >
              <OverviewCard amount={contactsSize} title='Contacts' linkPath={paths.dashboard.contact.index} iconSrc='/assets/iconly/iconly-glass-info.svg' />
            </Grid>
            <Grid
              xs={12}
              md={7}
            >
            </Grid>
            
            <Grid
              xs={12}
              md={7}
            >
              <OverviewInventoryItems/>
            </Grid>
            <Grid
              xs={12}
              md={5}
            >
              <OverviewTips
                sx={{ height: '100%' }}
                tips={[
                  {
                    title: '1: First time setup',
                    content: 'Go to Settings to define waste categories and link them to their object types.'
                  },
                  {
                    title: '2: Add more details',
                    content: 'You can try to add more details, for example objects within objects (CD-ROM drive that belongs to computer) or parent categories (e-waste, plastic)'
                  },
                  {
                    title: '3: Add waste records',
                    content: 'Login to the mobile app to add waste records and contacts to the database.'
                  },
                  {
                    title: '4: Manage waste inventory',
                    content: 'Click on Waste Inventory to see waste streams that have entered the collection system.'
                  }
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Page;
