import _ from 'lodash';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import axios from 'axios';
import jwtDecode, { JwtPayload } from "jwt-decode";
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from 'src/types/user';
import { Issuer } from 'src/utils/auth';
import dayjs from 'dayjs';

import { getAxiosConfig } from '../../api/api';
import { STORAGE_KEY } from '../../constants';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT'
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: User;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action =
  | InitializeAction
  | SignInAction
  | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export interface JWTPayload {
  name: string,
  email: string,
  userId: string,
  roleName: string
}

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  signIn: (email: string, password: string) => Promise<User | undefined>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: (email: string, password: string) => Promise.resolve<User | undefined>(undefined),
  signOut: () => Promise.resolve()
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(
    async (): Promise<void> => {
      try {
        const accessToken = window.sessionStorage.getItem(STORAGE_KEY);

        if (accessToken) {
          const payload = jwtDecode<JWTPayload>(accessToken);

          const tokenExpired = _.get(payload, 'exp') ? dayjs().isAfter(dayjs.unix(_.get(payload, 'exp', 0))) : true;

          const { name, email, userId, roleName } = payload;
          const user = { name, email, id: userId, roleName };

          if (tokenExpired === true) {
            dispatch({ type: ActionType.SIGN_OUT });
            return;
          }

          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    },
    [dispatch]
  );

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = useCallback(
    async (email: string, password: string): Promise<User | undefined> => {
      const axiosConfig = getAxiosConfig();

      return axios.post('/user/login', { user: { email, password } }, axiosConfig).then((response: any) => {
        const accessToken = response.data;

        sessionStorage.setItem(STORAGE_KEY, accessToken);
        const payload = jwtDecode<JWTPayload>(accessToken);
        const { name, email, userId, roleName } = payload;
        const user = { name, email, id: userId, roleName };
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            user
          }
        });
        return user;
      }).catch((e: any) => {
        const errorMessage = _.get(e, 'response.data') ? _.get(e, 'response.data') : _.get(e, 'message');
        throw new Error(errorMessage);
      })
    },
    [dispatch]
  );

  const signOut = useCallback(
    async (): Promise<void> => {
      sessionStorage.removeItem(STORAGE_KEY);
      dispatch({ type: ActionType.SIGN_OUT });
    },
    [dispatch]
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
