import _ from 'lodash';
import dayjs from 'dayjs';
import type { FC } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Tab,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
  Paper
} from '@mui/material';
import type { Inventory } from 'src/types/inventory';
import { getWasteCategoryNameById, getContactNameById } from 'src/utils/utils';


import { useContacts } from 'src/api/contact';
import { useInventoryItems } from 'src/api/inventory';
import { useTags } from 'src/api/tag';
import { useInventoryTypes } from 'src/api/inventoryType';

export const OverviewTable: FC = (props) => {

  const { data: inventoryItems } = useInventoryItems();
  const { data: inventoryTypes } = useInventoryTypes();
  const { data: tags } = useTags();
  const { data: contacts } = useContacts();

  const sortedInventoryItems = _.sortBy(inventoryItems, (inventoryItem) => dayjs(inventoryItem.createdAt)).reverse();
  const inventoryItemsTop10 = _.take(sortedInventoryItems, 10);
  return (
    <Card>
      <CardHeader
        title="Latest Waste Objects"
        sx={{ pb: 0 }}
      />
      <Tabs
        value="all"
        sx={{ px: 3 }}
      >
        <Tab
          label="All"
          value="all"
        />
      </Tabs>
      <Divider />
      <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}
                  aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell>Date</TableCell>
                      <TableCell>Tracking Number</TableCell>
                      <TableCell>Waste Category</TableCell>
                      <TableCell>Waste Object Type</TableCell>
                      <TableCell align="right">Quantity (pcs)</TableCell>
                      <TableCell align="right">Weight (kg)</TableCell>
                      <TableCell align="right">Contact</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(inventoryItemsTop10, (inventoryItem: Inventory) => (
                      <TableRow
                        key={inventoryItem.trackingNumber}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">{dayjs(inventoryItem.createdAt).format('DD-MM-YYYY [at] HH:mm')}</TableCell>
                        <TableCell align="left">{inventoryItem.trackingNumber}</TableCell>
                        <TableCell align="left">{getWasteCategoryNameById(tags, _.get(inventoryItem, 'wasteCategoryTypeId', ''))}</TableCell>
                        <TableCell align="left">{getWasteCategoryNameById(inventoryTypes, _.get(inventoryItem, 'inventoryTypeId', ''))}</TableCell>
                        <TableCell align="right">{inventoryItem.quantity}</TableCell>
                        <TableCell align="right">{inventoryItem.weight}</TableCell>
                        <TableCell align="right">{getContactNameById(contacts, _.get(inventoryItem, 'personId', ''))}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
    </Card>
  );
};