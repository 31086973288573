import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import toast from 'react-hot-toast'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { Tag } from 'src/types/tag';
import { getAxiosConfig, handleApiRequestPromise } from 'src/api/api';

const createTag = async (tag: Tag | undefined): Promise<Tag> => {
  const axiosConfig = getAxiosConfig();
  const { data }: AxiosResponse<Tag> = await axios.post('tag', { tag }, axiosConfig);
  return data;
}

const findTags = async (): Promise<Tag[]> => {
  const axiosConfig = getAxiosConfig();
  const { data }: AxiosResponse<Tag[]> = await axios.get('tag', axiosConfig);
  return _.sortBy(data, (item) => item.name);
};

const deleteTag = async (id: string): Promise<string> => {
  const axiosConfig = getAxiosConfig();
  await axios.delete(`tag/${id}`, axiosConfig);
  return id;
};

export const useTags = () => {
  return useQuery({
    queryKey: ["tags"],
    queryFn: async () => {
      return handleApiRequestPromise(findTags)
    },
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000, // consider data fresh for 60min
    cacheTime: 1000 * 60 * 60 * 24, // keep data in cache for 24hrs
    networkMode: 'offlineFirst',
    onError: (errorMessage: string) => toast.error(`Something went wrong: ${errorMessage}`)
  });
}

export const useCreateTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (tag: Tag) => handleApiRequestPromise(createTag, tag),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['tags'] })
    },
    onError: (errorMessage: string) => toast.error(`Something went wrong: ${errorMessage}`)
  })
}

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => handleApiRequestPromise(deleteTag, id),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['tags'] })
    },
    onError: (errorMessage: string) => toast.error(`Something went wrong: ${errorMessage}`)
  })
}