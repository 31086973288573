import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, SvgIcon } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import SellIcon from '@mui/icons-material/Sell';
import FeedIcon from '@mui/icons-material/Feed';

import { paths } from 'src/paths';

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  return [{
    items: [
      {
        title: 'Dashboard',
        path: paths.dashboard.index,
        icon: (
          <SvgIcon fontSize="small">
            <GridViewOutlinedIcon />
          </SvgIcon>
        )
      },
      {
        title: 'Activities',
        path: paths.dashboard.activity.index,
        icon: (
          <SvgIcon fontSize="small">
            <AutoAwesomeMotionIcon />
          </SvgIcon>
        )
      },
      {
        title: 'Inventory',
        path: paths.dashboard.inventory.index,
        icon: (
          <SvgIcon fontSize="small">
            <Inventory2OutlinedIcon />
          </SvgIcon>
        )
      },
      {
        title: 'Contacts',
        path: paths.dashboard.contact.index,
        icon: (
          <SvgIcon fontSize="small">
            <ContactsOutlinedIcon />
          </SvgIcon>
        )
      },
      {
        title: 'Locations',
        path: paths.dashboard.location.index,
        icon: (
          <SvgIcon fontSize="small">
            <LocationOnOutlinedIcon />
          </SvgIcon>
        )
      },
      {
        title: ' Settings',
        icon: (
          <SvgIcon fontSize="small">
            <TuneOutlinedIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: 'Manage Tags',
            path: paths.dashboard.tag.index,
            icon: (
              <SvgIcon fontSize="small">
                <SellIcon />
              </SvgIcon>
            )
          },
          {
            title: 'Manage Activity Types',
            path: paths.dashboard.activityType.index,
            icon: (
              <SvgIcon fontSize="small">
                <AutoAwesomeMotionIcon />
              </SvgIcon>
            )
          },
          {
            title: 'Manage Inventory Types',
            path: paths.dashboard.inventoryType.index,
            icon: (
              <SvgIcon fontSize="small">
                <Inventory2OutlinedIcon />
              </SvgIcon>
            )
          },
          {
            title: 'Project Settings',
            path: paths.dashboard.projectSetting.index,
            icon: (
              <SvgIcon fontSize="small">
                <TuneOutlinedIcon />
              </SvgIcon>
            )
          },
        ]
      }
    ]
  }]
};
