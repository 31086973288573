import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import toast from 'react-hot-toast'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { Contact } from 'src/types/contact';
import { getAxiosConfig, handleApiRequestPromise } from 'src/api/api';

const createContact = async (contact: Contact): Promise<string | undefined> => {
  const axiosConfig = getAxiosConfig();
  const { data }: AxiosResponse<Contact> = await axios.post(`/contact`, { contact }, axiosConfig);
  const contactId = data._id;
  return contactId;
}

const findContacts = async (): Promise<Contact[]> => {
  const axiosConfig = getAxiosConfig();
  const { data }: AxiosResponse<Contact[]> =  await axios.get('/contact', axiosConfig);
  return data;
};

const deleteContact = async (contactId: string): Promise<string> => {
  const axiosConfig = getAxiosConfig();
  await axios.delete(`contact/${contactId}`, axiosConfig);
  return contactId
};

export const useContacts = () => {
  return useQuery({
    queryKey: ["contact"],
    queryFn: async () => {
      return handleApiRequestPromise(findContacts)
    },
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
  });
}

export const useCreateContact = () => {
  return useMutation({
    mutationFn: async (contact: Contact) => { return await handleApiRequestPromise(createContact, contact) },
    onSuccess: (contactId) => {
      return contactId;
    }
  })
}

export const useDeleteContact = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => handleApiRequestPromise(deleteContact, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['contact'] })
    },
    onError: (errorMessage: string) => toast.error(`Something went wrong: ${errorMessage}`)
  })
}