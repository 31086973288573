const STORAGE_KEY = 'accessToken';
const USER_ROLE_OPERATOR = 'OPERATOR';
const USER_ROLE_ADMIN = 'ADMIN';

const SETTING_APPLICATION = 'APPLICATION';
const SETTING_DATABASE = 'DATABASE';

export {
    STORAGE_KEY,
    USER_ROLE_OPERATOR,
    USER_ROLE_ADMIN,
    SETTING_APPLICATION,
    SETTING_DATABASE
}
