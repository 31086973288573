import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { Inventory } from 'src/types/inventory';
import { getAxiosConfig, handleApiRequestPromise } from 'src/api/api';

interface CreateInventoryItemParams {
  inventoryItems: Inventory[],
  personId: string | undefined
}

const getInventoryItems = async (): Promise<Inventory[]> => {
  const axiosConfig = getAxiosConfig();
  const { data }: AxiosResponse<Inventory[]> =  await axios.get('inventory', axiosConfig);
  return data;
};

const createInventoryItem = async (params: CreateInventoryItemParams): Promise<Inventory[]> => {
  const { inventoryItems, personId } = params;
  const axiosConfig = getAxiosConfig();
  const { data } = await axios.post(`inventory`, { inventoryItems, person: { personId } }, axiosConfig);
  return data;
}

export const useInventoryItems = () => {
  return useQuery({
    queryKey: ["inventory"],
    queryFn: async () => {
      return handleApiRequestPromise(getInventoryItems)
    },
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
  });
}

export const useCreateInventoryItems = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: CreateInventoryItemParams) => handleApiRequestPromise(createInventoryItem, params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inventory'] })
    }
  })
}